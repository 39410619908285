document.addEventListener("alpine:init", () => {
	Alpine.data("dropdownData", () => ({
		show: false,
		toggleDropdown() {
			this.show = !this.show
		},
		clickOutsideHandler(e) {
			if (!this.$refs.button.contains(e.target)) {
				this.show = false
			}
		},
	}))
})
