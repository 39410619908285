// the order of these imports is sensitive
import "./jquery.js"
import "jquery-ui/dist/jquery-ui.js"
import "jquery-ui/ui/widget.js"
import "jquery-ui/ui/widgets/autocomplete.js"
import "jquery-ui/ui/widgets/datepicker.js"
import "jquery-ui/ui/widgets/menu.js"
import "jquery-ui/ui/unique-id.js"
import "jquery-ui/ui/keycode.js"
import "jquery-ui/ui/safe-active-element.js"
import "jquery-ui/ui/widgets/accordion.js"
import "./tooltip.js"
import "./dropdown.js"
import Tagify from "@yaireo/tagify"

window.Tagify = Tagify

window.anErrorOccurred = function (message) {
	console.error(message)
	alert(message)

	if (app_env == "local" || app_env == "testing") {
		throw message
	}
}

window.removeCopyToClipboardElements = function () {
	if (
		typeof navigator?.clipboard?.writeText == "undefined" ||
		!navigator?.clipboard?.writeText ||
		typeof navigator?.clipboard?.write == "undefined" ||
		!navigator?.clipboard?.write
	) {
		$(".copyToClipboardButton").remove()
		console.warn("navigator.clipboard has problems, so we removed the .copyToClipboardButton elements")
	}
}

// https://stackoverflow.com/a/11381730/3642588
window.onDesktop = (function () {
	const toMatch = [/Android/i, /webOS/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i]

	return !toMatch.some((toMatchItem) => {
		return navigator.userAgent.match(toMatchItem)
	})
})()

window.showChangeHistory = function (data) {
	showChangeHistoryGeneric("Right", data)
}

// https://stackoverflow.com/a/995374/3642588
window.textareaHeightAdjust = function (element) {
	element.style.height = "1px"
	element.style.height = Math.max(40, 3 + element.scrollHeight) + "px"
}

/**
 * @var tokenyString: string E.g. "Good morning, {userName}!"
 * @var tokens: object An associative array of tokens; {userName: "Superman"}
 * @link https://stackoverflow.com/a/15604206/3642588
 * @link https://stackoverflow.com/a/9310752/3642588
 */
window.detokenize = function (tokenyString, tokens) {
	var re = new RegExp(
		Object.keys(tokens)
			.map((key) => `{${key.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&")}}`)
			.join("|"),
		"gi"
	)
	return tokenyString.replace(re, function (matched) {
		return tokens[matched.substring(1, matched.length - 1)]
	})
}

window.resizeElement = function (textarea, addPixels = 0) {
	textarea.style.height = ""
	textarea.style.height = textarea.scrollHeight + addPixels + "px"
}

// adds a listener to all `textarea.auto-height` elements
// can be called multiple times to add more textareas
// is called automatically when page loads
window.initAutoTextareaHeight = function () {
	$("textarea.auto-height").each(function () {
		let textarea = this

		if (!$(textarea).hasClass("auto-height-initialized")) {
			$(textarea).addClass("auto-height-initialized")

			$(textarea).css("overflow", "hidden")
			textareaHeightAdjust(textarea)

			respondToVisibility(textarea, function (visible) {
				if (visible) {
					textareaHeightAdjust(textarea)
				}
			})

			textarea.addEventListener("input", function () {
				textareaHeightAdjust(textarea)
			})
		}
	})
}

// https://stackoverflow.com/a/44670818/3642588
// Start observing visibility of element. On change, the
//   the callback is called with Boolean visibility as
//   argument:
function respondToVisibility(element, callback) {
	var options = {
		root: document.documentElement,
	}

	var observer = new IntersectionObserver((entries, observer) => {
		entries.forEach((entry) => {
			callback(entry.intersectionRatio > 0)
		})
	}, options)

	observer.observe(element)
}

$(function () {
	initAutoTextareaHeight()
})

function showChangeHistoryGeneric(side, data) {
	if (typeof data.name == "undefined") {
		$("#changeHistory" + side).fadeOut()
		return
	}

	var id = ""
	if (data.class == "PersonGroups") {
		if (data.user_id != null) id = "u" + data.user_id
		if (data.group_id != null && data.groupPreferred) id = "g" + data.group_id
	} else {
		if (data.user_id != null) id = data.user_id
		if (data.group_id != null) id = data.group_id
		if (data.folder_id != null) id = data.folder_id
		if (data.file_id != null) id = data.file_id
		if (data.session_id != null) id = data.session_id
		if (data.division_id != null) id = data.division_id
	}

	var link = $("<a href='/changeHistory/" + data.class + "/" + id + "'></a>").text(data.date + " - " + data.name)
	$("#changeHistory" + side)
		.empty()
		.append(link)
	$("#changeHistory" + side).fadeIn()
}

/** @TODO these two functions should be moved completely to x-user-picker and x-group-picker components, however PersonSearch still references them directly for now */

/**
 * @param {string} inputId the id of the name input
 * @param {string} hiddenId the id of the hidden form input to store the user_id
 * @param {string} buttonId the id of the form button to deactivate until a user is selected
 * @param {function} onSelected a function to call when a person is selected
 * @param {object} options an object to pass to the autocomplete user search on the server, e.g. {"showSpouses":true}
 * @returns {undefined}
 */
window.initUserSelectorJq = function (input, hidden, button, onSelected, options) {
	if (button != null) {
		button.prop("disabled", true)
	}

	var source = options == null ? "/ajaxUserSearch?" : "/ajaxUserSearch?" + $.param(options)

	input.autocomplete({
		source: source,
		select: function (event, ui) {
			event.preventDefault()
			input.val(ui.item.label)
			hidden.val(ui.item.value)
			if (button != null) {
				button.prop("disabled", false)
			}
			if (onSelected) onSelected(ui.item.value, ui.item.label, hidden)
		},
		focus: function (event, ui) {
			event.preventDefault()
			input.val(ui.item.label)
			hidden.val(ui.item.value)
			if (button != null) {
				button.prop("disabled", false)
			}
			if (onSelected) onSelected(ui.item.value, ui.item.label, hidden)
		},
		position: { my: "left bottom", at: "left top", collision: "flipfit" },
		open: function () {
			// If the autocomplete is in an x-popup, it doesn't show unless z-index is this big
			$(this).autocomplete("widget").css("z-index", 9999999)
			return false
		},
	})

	input.keyup(() => {
		if (input.val() == "") {
			button.prop("disabled", true)
		}
	})

	if (button != null) {
		button.keypress(function () {
			button.prop("disabled", true)
		})
	}
}

window.initGroupSelectorJq = function (input, hidden, button, onSelected, options) {
	// hideAutoComplete(input);

	if (button != null) {
		button.prop("disabled", true)
	}
	input.addClass("groupNameInput")

	var source = options == null ? "/ajaxGroupSearch?" : "/ajaxGroupSearch?" + $.param(options)

	input.autocomplete({
		source: source,
		select: function (event, ui) {
			event.preventDefault()
			input.val(ui.item.label)
			hidden.val(ui.item.value)
			if (button != null) {
				button.prop("disabled", false)
			}
			if (onSelected) onSelected(ui.item.value, ui.item.label, hidden)
		},
		focus: function (event, ui) {
			event.preventDefault()
			input.val(ui.item.label)
			hidden.val(ui.item.value)
			if (button != null) {
				button.prop("disabled", false)
			}
			if (onSelected) onSelected(ui.item.value, ui.item.label, hidden)
		},
		position: { my: "left bottom", at: "left top", collision: "flipfit" },
	})

	input.keypress(function () {
		if (button != null) {
			button.prop("disabled", true)
		}
	})
}

/**
 * Debounce function:
 * if you want to wait until an event stops firing before executing a function
 */
window.debounce = (callback, wait) => {
	let timeout

	return function executedFunction(...args) {
		const later = () => {
			clearTimeout(timeout)
			callback(...args)
		}

		clearTimeout(timeout)
		timeout = setTimeout(later, wait)
	}
}

/**
 * Throttle function:
 * if you want to limit the times a function is executed
 */
window.throttle = (callback, limit) => {
	var waiting = false
	return function () {
		if (!waiting) {
			callback.apply(this, arguments)
			waiting = true
			setTimeout(function () {
				waiting = false
			}, limit)
		}
	}
}

window.formatTimeElements = () => {
	document.querySelectorAll("time").forEach((time) => {
		let date = new Date(time.dateTime)
		let month = date.toLocaleString("default", { month: "short" })
		let day = date.getDate()
		let year = date.getFullYear()
		let hour = (date.getHours() + "").padStart(2, "0")
		let minute = (date.getMinutes() + "").padStart(2, "0")
		let second = (date.getSeconds() + "").padStart(2, "0")
		let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
		time.innerText = `${month} ${day}, ${year}, ${hour}:${minute}:${second} ${timezone}`
	})
}

/**
 * HTML escapes the input string
 * @param {string} iffyString
 * @return {string}
 */
window.e = function (iffyString) {
	let span = document.createElement("span")
	span.innerText = iffyString
	return span.innerHTML
}

/**
 * Loads HTML from the given path, and sets the given Element's innerHTML to it.
 * This function also handles the execution of all script tags in the response.
 *
 * @param {Element} element into which to insert the loaded HTML
 * @param {string} path to HTML to load
 * @param {boolean} allowRedirect whether to follow redirections to consider them errors
 * @returns {Promise<number>} response status code, or zero if network error (or redirection while it not being allowed)
 */
window.loadHtml = async function (element, path, allowRedirect = false) {
	/**
	 * We use an in-memory element as a kind buffer so we can manipulate scripts and
	 * stuff without AlpineJS messing with us. Just before returning, we move all of
	 * this buffer's children to the target element in such a way that AlpineJS
	 * doesn't interfere.
	 */
	let buffer = document.createElement("div")

	let response
	try {
		response = await fetch(path, {
			headers: { Accept: "text/html" },
			redirect: allowRedirect ? "follow" : "error",
		})
		buffer.innerHTML = await response.text()
	} catch (e) {
		return 0
	}

	buffer.querySelectorAll("script").forEach((script) => {
		let newScript = document.createElement("script")
		newScript.innerHTML = script.innerHTML

		for (let attributeName of ["async", "defer", "src", "type"]) {
			let value = script.getAttribute(attributeName)
			if (value !== null) {
				newScript.setAttribute(attributeName, value)
			}
		}

		script.remove()
		buffer.appendChild(newScript)
	})

	element.innerHTML = ""
	element.append(...buffer.children)
	return response.status
}

/**
 * Loads HTML from the given path, and appends it to the given Element's innerHTML.
 * This function also handles the execution of all script tags in the response.
 *
 * @param {Element} element into which to append the loaded HTML
 * @param {string} path to HTML to load
 * @param {boolean} allowRedirect whether to follow redirections to consider them errors
 * @returns {number} response status code, or zero if network error (or redirection while it not being allowed)
 */
window.loadHtmlAppend = async function (element, path, allowRedirect = false) {
	let buffer = document.createElement("div")
	const status = await window.loadHtml(buffer, path, allowRedirect)
	element.append(...buffer.children)
	return status
}

/**
 * Loads JSON from the given path, and returns its parsed value, or undefined if an error occurred
 *
 * @param {string} path to JSON to load
 * @param {boolean} allowRedirect whether to follow redirections to consider them errors
 * @returns {number|string|boolean|null|undefined} parsed response, or undefined if network error (or redirection while it not being allowed)
 */
window.fetchJson = async function (path, allowRedirect = false) {
	let response
	try {
		response = await fetch(path, {
			headers: { Accept: "application/json" },
			redirect: allowRedirect ? "follow" : "error",
		})
		return await response.json()
	} catch (e) {
		return undefined
	}
}

/**
 * @deprecated use `window.loadHtml()` instead
 */
window.loadHtmlTeleportable = async function (element, path, allowRedirect = false) {
	return await window.loadHtml(element, path, allowRedirect)
}

/**
 * @param {HTMLLabelElement} provinceLabel
 * @param {HTMLSelectElement} countryPicker
 * @param {string} hiddenClass class to add to the province wrapper div when it should be hidden
 * @returns {void}
 */
window.registerProvinceInputLabelWithCountryPicker = function (
	provinceLabel,
	countryPicker,
	hiddenClass = "invisible"
) {
	updateProvinceLabel(provinceLabel, countryPicker, hiddenClass)
	countryPicker.addEventListener("change", () => {
		updateProvinceLabel(provinceLabel, countryPicker, hiddenClass)
	})
}

/**
 * @param {HTMLLabelElement} provinceLabel
 * @param {HTMLSelectElement} countryPicker
 * @param {string} hiddenClass class to add to the province wrapper div when it should be hidden
 * @returns {void}
 */
function updateProvinceLabel(provinceLabel, countryPicker, hiddenClass) {
	let label =
		countryPicker.options[countryPicker.selectedIndex]?.dataset?.province ?? window.defaultFirstLevelAdminDivNames

	if (label == "") {
		provinceLabel.closest(".xTextInputComponent").classList.add(hiddenClass)
		provinceLabel.closest(".xTextInputComponent").querySelector("input").disabled = true
	} else {
		provinceLabel.closest(".xTextInputComponent").classList.remove(hiddenClass)
		provinceLabel.closest(".xTextInputComponent").querySelector("input").disabled = false
		provinceLabel.innerText = label
	}
}

/**
 * @param {HTMLLabelElement} postalCodeLabel
 * @param {HTMLSelectElement} countryPicker
 * @returns {void}
 */
window.registerPostalCodeInputLabelWithCountryPicker = function (postalCodeLabel, countryPicker) {
	updatePostalCodeLabel(postalCodeLabel, countryPicker)
	countryPicker.addEventListener("change", () => {
		updatePostalCodeLabel(postalCodeLabel, countryPicker)
	})
}

/**
 * @param {HTMLLabelElement} postalCodeLabel
 * @param {HTMLSelectElement} countryPicker
 * @returns {void}
 */
function updatePostalCodeLabel(postalCodeLabel, countryPicker) {
	postalCodeLabel.innerText =
		countryPicker.options[countryPicker.selectedIndex]?.dataset?.postalCode ?? window.defaultPostalCodeName
}

